import { Typography } from "@mui/material";

function TermsOfUseAndPrivacyPolicy()
{
    return (
        <Typography variant="body1" sx={{maxWidth: "900px", padding: "40px"}}>
            Terms of Use<br /><br />
            1. Introduction<br />
            Welcome to AuthenTick. By accessing our website and using our services, you agree to be bound by these terms and conditions. Please read them carefully.<br />
            2. Services Description<br />
            AuthenTick provides an online luxury watch authentication service. Users submit photos of their watches for authentication and receive a report regarding the watch’s authenticity.<br />
            3. User Responsibilities<br />

            You must provide accurate and truthful information about your watch.<br />
            You agree to comply with all local laws regarding online conduct and acceptable content.<br />
            You are responsible for maintaining the confidentiality of your login and account information.<br />
            4. Intellectual Property<br />
            All content provided on AuthenTick, such as text, graphics, logos, images, and software, is the property of AuthenTick or its content suppliers and protected by international copyright and intellectual property laws.<br />
            5. Disclaimer of Warranties<br />
            The service is provided "as is". AuthenTick makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property.<br />
            6. Limitations of Liability<br />
            AuthenTick will not be liable for any damages that arise with the use or inability to use the services.<br />
            7. Amendments<br />
            We reserve the right to amend these terms at any time. Your continued use of the service will signify your acceptance of any adjustment to these terms.<br />
            8. Governing Law<br />
            Any claim relating to AuthenTick’s services shall be governed by the laws of our jurisdiction without regard to its conflict of law provisions.<br /><br />
            Privacy Policy<br /><br />
            1. Introduction<br />
            Your privacy is important to us. This policy outlines the types of personal information we receive and collect when you use AuthenTick’s services, as well as some of the steps we take to safeguard information.<br />
            2. Information Collection and Use<br />

            Personal Information: We collect personal information such as names, email addresses, and watch details when you submit them for our services.<br />
            Cookies: We may use cookies to enhance your experience, gather general visitor information, and track visits to our website.<br />
            3. Information Sharing<br />
            We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.<br />
            4. Security<br />
            We implement a variety of security measures to maintain the safety of your personal information when you submit a request or enter, submit, or access your personal information.<br />
            5. Changes to this Privacy Policy<br />
            AuthenTick has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage users to frequently check this page for any changes.<br />
            6. Contact Information<br />
            If you have any questions about our Terms of Use or Privacy Policy, please contact us at <a href="mailto:contact@authentick.ca" style={{ color: "#4caf50" }}>contact@authentick.ca</a>.<br />
        </Typography>
    )
}

export default TermsOfUseAndPrivacyPolicy;