import { useState, useEffect } from "react";
import { Button, Divider, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Backdrop, CircularProgress, FormGroup, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { PaymentElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCheckout from "./StripeCheckout";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Link } from "react-router-dom";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const stripePromise = loadStripe("pk_live_kmwn6oD3q74jnISoTXE4AafQ")


function RegisterWatch() {

  const [images, setImages] = useState([]);

  const [submittedDialogOpen, setSubmittedDialogOpen] = useState(false);

  const [stripeOptions, setStripeOptions] = useState({ clientSecret: "", appearance: { theme: "night" } });

  const [isProcessingPayment, setIsProcessingPayment] = useState(false);

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");

  const uploadImage = (image) => {
    const url = 'https://func-komodo.azurewebsites.net/api/UploadImage?code=KOz0bmqc5me9fS3Gi_yAXV8kVjHrk6DNXu3jXfS7meA3AzFubDrRGg==';
    const formData = new FormData();
    formData.append('image', image);

    try {
      fetch(url, {
        method: 'POST',
        body: formData
      }).then(response => response.json()).then(json => setImages([...images, json["imageURL"]]));
    } catch (error) {
      console.error('Error occurred while uploading image:', error);
      return null;
    }
  }

  const handleImagesChange = (e) => {
    uploadImage(e.target.files[0]);
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const handleSubmittedDialogOpen = () => {
    setSubmittedDialogOpen(true);
  }

  const handleSubmittedDialogClose = () => {
    setSubmittedDialogOpen(false);
  }

  const resetState = () => {
    setImages([]);
  }

  const validateWatchRegistrationSubmission = () => {
    if (!(images.length == 3)) {
      alert("Please upload the 3 images required.")
      return false;
    }
    
    if (email.length == 0)
    {
      alert("Please enter your email.");
      return false;
    }
    return true;
  }

  useEffect(() => {
    loadStripeClientSecret();
  }, [])

  const loadStripeClientSecret = () => {
    const url = "https://func-komodo.azurewebsites.net/api/CreateCheckoutSession?code=ezNjeEb-Uap63eGuEbqU6hmi9toeW-akMiZ3apDsT3S8AzFu7y-AAw==";

    fetch(url).then(response => response.text()).then(text => setStripeOptions({ ...stripeOptions, clientSecret: text }));
  }

  return (
    <>
      <Grid container sx={{ width: "90%", maxWidth: "900px", paddingTop: "50px" }} spacing={1}>
        <Grid xs={12} sx={{display: "inline-flex", alignItems: "center", justifyContent: "center"}}>
          <a href="https://authentick.ca"><img src="/AuthenTickLogo.png" width="300" /></a>
          {/* <Typography variant="h1">AuthenTick</Typography> */}
        </Grid>
        <Grid xs={12} sx={{display: "flex", justifyContent: "center"}}>
          <Typography variant="h4">AI Powered</Typography>
        </Grid>
        <Grid xs={12} sx={{marginTop: "50px"}}>
          <Typography variant="h2">
            Verify a watch
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
        <Grid xs={12}>
          <Typography variant="h5">
            In order to verify your watch's authenticity, our AI requires 3 photos:
          </Typography>
          
        </Grid>
        <Grid md={4}>
          <Typography variant="h4">1. Front</Typography>
          <img src="/WatchFrontal.jpg" style={{ width: "100%" }} />
        </Grid>
        <Grid md={4}>
          <Typography variant="h4">2. Inner Bezel</Typography>
          <img src="/WatchInnerBezel.jpg" style={{ width: "100%" }} />
        </Grid>
        <Grid md={4}>
          <Typography variant="h4">3. Side (crown)</Typography>
          <img src="/WatchSide.jpg" style={{ width: "100%" }} />
        </Grid>
        <Grid xs={12} sx={{display: "flex", justifyContent: "center"}}>
          <Typography variant="h7">
            (the photos above are examples)
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Divider />
        </Grid>
        {images.map(url => (
          <Grid md={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <img src={url} key={url} style={{ width: "100%" }} />
            <Button onClick={() => setImages(images.filter(image => image != url))}>X</Button>
          </Grid>
        ))}
        <Grid xs={12} sx={{marginTop: "30px"}}>
          <Button
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Upload image
            <VisuallyHiddenInput accept="image/*" type="file" onChange={handleImagesChange} />
          </Button>


        </Grid>
        <Grid xs={12} sx={{marginTop: "30px"}}>
          <TextField sx={{width: "100%"}} placeholder="john@email.com" label="Email (required)" value={email} onChange={handleEmailChange} /> 
        </Grid>
        <Grid xs={12}>
          {stripeOptions.clientSecret &&
            <>
              <Typography variant="h4" sx={{ marginTop: "30px" }}>$99.95 USD</Typography>
              <Elements stripe={stripePromise} options={stripeOptions}>
                <StripeCheckout email={email} images={images} validateWatchRegistrationSubmission={validateWatchRegistrationSubmission} handleSubmitted={handleSubmittedDialogOpen} />
              </Elements>
            </>
          }
        </Grid>
        <Grid xs={12}>
          <Typography variant="body1">Questions? <a target="_blank" href="https://authentick.ca/#contact" style={{ color: "#4caf50" }}>Contact us here</a>.</Typography>
        </Grid>
        <Grid xs={12} sx={{display: "inline-flex", justifyContent: "right"}}>
            v1.0.9
        </Grid>
        <Dialog open={submittedDialogOpen}>
          <DialogTitle>
            Watch Submitted
          </DialogTitle>
          <DialogContent>
            Your watch has been sumbitted for review. You will receive an email with the results of your watch's authenticity.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => window.location.reload()}>Verify another watch</Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default RegisterWatch;