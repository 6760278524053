import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { yellow, green } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, createBrowserRouter, RouterProvider } from 'react-router-dom';
import TermsOfUseAndPrivacyPolicy from './TermsOfUseAndPrivacyPolicy';
import AIPolicy from './AIPolicy';

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: green
  }
})

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  },
  {
    path: "/termsofuse-privacypolicy",
    element: <TermsOfUseAndPrivacyPolicy />
  },
  {
    path: "/ai-policy",
    element: <AIPolicy />
  }
])


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
