import { Typography } from "@mui/material";

function AIPolicy()
{
    return (
        <Typography variant="body1" sx={{maxWidth: "900px", padding: "40px"}}>
            AI Use Policy for AuthenTick<br /><br />

            1. Introduction<br /><br />

            At AuthenTick, we leverage Artificial Intelligence (AI) technology to enhance the accuracy and efficiency of our watch authentication services. This AI Use Policy outlines how we deploy AI technologies responsibly to serve our customers.<br /><br />
            2. Purpose of AI Usage<br /><br />

            Our AI systems are designed to assist in analyzing images and data related to luxury watches to determine their authenticity. The AI aids in detecting inconsistencies and verifying characteristics that correspond to genuine articles.<br /><br />
            3. Data Handling<br /><br />

            Data Collection: We collect images and relevant information about the watches solely for the purpose of authentication.<br />
            Data Privacy: All data processed by our AI is handled in strict accordance with our Privacy Policy, ensuring confidentiality and integrity.<br />
            Data Security: We implement robust security measures to protect the data from unauthorized access or breaches.<br /><br />
            4. AI Decision Making<br /><br />
            Human Oversight: While our AI provides recommendations and analyses, final authentication decisions are reviewed and approved by our expert human staff to ensure accuracy.<br />
            Transparency: We strive for transparency in our AI processes and provide explanations for AI-driven decisions upon customer request.<br /><br />
            5. AI Ethics<br /><br />
            We commit to ethical AI use, ensuring that our AI systems operate in a non-discriminatory and fair manner, free from biases that could affect the authentication process.<br /><br />
            6. Customer Rights<br /><br />

            Right to Information: Customers have the right to know how their data is being used and how AI is involved in the authentication process.<br /><br />
            7. Compliance and Updates<br /><br />
            Our AI systems and this policy are regularly reviewed and updated to comply with applicable laws and best practices in AI governance.<br /><br />
            8. Contact Information<br /><br />

            For any questions or concerns regarding our AI Use Policy or AI practices, please contact us at <a href="mailto:contact@authentick.ca" style={{ color: "#4caf50" }}>contact@authentick.ca</a>.
        </Typography>
    )
}

export default AIPolicy;