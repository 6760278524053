import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

function StripeCheckout(props)
{

    const stripe = useStripe();

    const elements = useElements();

    const [isProcessingPayment, setIsProcessingPayment] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const submit = () => {

        debugger;
        if (!props.validateWatchRegistrationSubmission())
        {
            return;
        }

        if (!stripe || !elements) {
            return;
        }

        setIsProcessingPayment(true);

        stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: ``
                //receipt_email: data["email"]
            },
            redirect: "if_required"
        }).then(response => {
            console.log(response.error);
            
            if (!response.error)
            {
                const baseUrl = "https://func-komodo.azurewebsites.net/api/RegisterWatch?code=7DRXAgf4ajvVvFphRJywSb93N9CV12c7xkh-LmqikewrAzFuX3Y1Hg==";
                
                fetch(baseUrl, {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        "email": props.email,
                        "images": props.images
                    })
                }).then(response => {
                    setIsProcessingPayment(false);
                    props.handleSubmitted();
                });
            }

            else if (response.error.type != "validation_error") {
                setIsProcessingPayment(false);
                setErrorMessage(response.error.message);
            }

        })
    }

    return (
        <>
            <PaymentElement />
            <Typography sx={{ color: "red" }}>{errorMessage}</Typography>
            <Button onClick={submit} disabled={isProcessingPayment} variant="contained" sx={{marginTop: "30px"}}>Submit</Button><br /><br />
            <i>*By submitting your photos, you agree to our <Link to="/termsofuse-privacypolicy" style={{ color: "#4caf50" }}>terms of use and privacy policy</Link>, as well as our <Link to="/ai-policy" style={{ color: "#4caf50" }}>AI policy</Link>.</i>
        </>
    )
}

export default StripeCheckout;